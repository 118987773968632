window.addEventListener("load", function () {
  // obtain plugin
  var cc = initCookieConsent();

  // run plugin with your configuration
  cc.run({
    current_lang: "de",
    autoclear_cookies: true, // default: false
    page_scripts: true, // default: false

    // mode: 'opt-in'                          // default: 'opt-in'; value: 'opt-in' or 'opt-out'
    // delay: 0,                               // default: 0
    // auto_language: '',                      // default: null; could also be 'browser' or 'document'
    // autorun: true,                          // default: true
    // force_consent: false,                   // default: false
    // hide_from_bots: false,                  // default: false
    // remove_cookie_tables: false             // default: false
    // cookie_name: 'cc_cookie',               // default: 'cc_cookie'
    // cookie_expiration: 182,                 // default: 182 (days)
    // cookie_necessary_only_expiration: 182   // default: disabled
    // cookie_domain: location.hostname,       // default: current domain
    // cookie_path: '/',                       // default: root
    // cookie_same_site: 'Lax',                // default: 'Lax'
    // use_rfc_cookie: false,                  // default: false
    // revision: 0,                            // default: 0

    onFirstAction: function (user_preferences, cookie) {
      // callback triggered only once on the first accept/reject action
    },

    onAccept: function (cookie) {
      // callback triggered on the first accept/reject action, and after each page load
    },

    onChange: function (cookie, changed_categories) {
      // callback triggered when user changes preferences after consent has already been given
    },

    languages: {
      de: {
        consent_modal: {
          title: "Auf dieser Seite werden Cookies verwendet.",
          description:
            'Hi, wir verwenden auf dieser Website ausschließlich technisch notwendige Cookies. Weitere oder Drittanbieter Cookies kommen nicht zum Einsatz. <button type="button" data-cc="c-settings" class="cc-link">Weitere Informationen</button>',
          primary_btn: {
            text: "Alles klar",
            role: "accept_all" // 'accept_selected' or 'accept_all'
          }
        },
        settings_modal: {
          title: "Cookie Einstellungen",
          save_settings_btn: "Save settings",
          accept_all_btn: "Alles klar",
          //   reject_all_btn: "Reject all",
          close_btn_label: "Schließen",
          cookie_table_headers: [
            { col1: "Name" },
            { col2: "Domain" },
            { col3: "Ablaufzeit" },
            { col4: "Beschreibung" }
          ],
          blocks: [
            {
              title: "Cookies auf dieser Seite",
              description:
                'Wir nutzen auf dieser Website ausschließlich technisch notwendige Cookies. Weitere oder Drittanbieter Cookies kommen nicht zum Einsatz. Für weitere Informationen zu Cookies oder der Verarbeitung von personenbezogenen Daten lesen Sie bitte unsere <a href="/datenschutzinformation#cookies" class="cc-link">Datenschutzinformation</a>.'
            },
            {
              title: "Technisch erforderliche Cookies",
              description:
                "Diese Cookies sind für die Funktion der Website erforderlich. Ohne Sie könnte die Website nicht richtig funktionieren.",
              toggle: {
                value: "necessary",
                enabled: true,
                readonly: true // cookie categories with readonly=true are all treated as "necessary cookies"
              },
              cookie_table: [
                // list of all expected cookies
                {
                  col1: "django_language", // match all cookies starting with "_ga"
                  col2: "ffk-ulb.de",
                  col3: "Bis zum Beenden des Browsers",
                  col4: "Speichert die präferierte Sprache des Websitebesuchers.",
                  is_regex: true
                },
                {
                  col1: "csrftoken",
                  col2: "ffk-ulb.de",
                  col3: "1 Jahr",
                  col4: "Schutz vor Website-übergreifender Fälschung von Anfragen (CSRF)"
                },
                {
                  col1: "cc_cookie",
                  col2: "ffk-ulb.de",
                  col3: "6 Monate",
                  col4: "Speichern von Cookie Einstellungen des Websitebesuchers."
                }
              ]
            },
            // {
            //   title: "Performance and Analytics cookies",
            //   description: "These cookies allow the website to remember the choices you have made in the past",
            //   toggle: {
            //     value: "analytics", // your cookie category
            //     enabled: false,
            //     readonly: false
            //   },
            //   cookie_table: [
            //     // list of all expected cookies
            //     {
            //       col1: "^_ga", // match all cookies starting with "_ga"
            //       col2: "google.com",
            //       col3: "2 years",
            //       col4: "description ...",
            //       is_regex: true
            //     },
            //     {
            //       col1: "_gid",
            //       col2: "google.com",
            //       col3: "1 day",
            //       col4: "description ..."
            //     }
            //   ]
            // },
            // {
            //   title: "Advertisement and Targeting cookies",
            //   description:
            //     "These cookies collect information about how you use the website, which pages you visited and which links you clicked on. All of the data is anonymized and cannot be used to identify you",
            //   toggle: {
            //     value: "targeting",
            //     enabled: false,
            //     readonly: false
            //   }
            // },
            {
              title: "Weitere Informationen",
              description:
                'Wünschen Sie weitere Informationen oder haben Fragen zum Datenschutz richten Sie Ihre Anfrage gerne per Mail an <a class="cc-link" href="mailto:datenschutz@ffk-ulb.de">datenschutz@ffk-ulb.de</a>.'
            }
          ]
        }
      }
    }
  });
});
