// ------------------------------------------------------- //
//   Make a sticky navbar on scrolling
// ------------------------------------------------------ //
import $ from "jquery";

$(window).scroll(function () {
  var body = $("body"),
    stickyNavbar = $("nav.navbar.fixed-top"),
    header = $(".header");

  function makeItFixed(x) {
    if ($(window).scrollTop() > x) {
      stickyNavbar.addClass("shrink");
      if (!header.hasClass("header-absolute")) {
        // body.css("padding-top", stickyNavbar.outerHeight());
        body.data("smooth-scroll-offset", stickyNavbar.outerHeight());
      }
    } else {
      stickyNavbar.removeClass("shrink");
      // body.css("padding-top", "0");
    }
  }
  makeItFixed(0);
});
