import $ from "jquery";
import "regenerator-runtime/runtime";

/*
    On submiting the form, send the POST ajax
    request to server and after successfull submission
    display the object.
*/
const form = "#booking-form";
const button = "#btn-booking";
let loading = false;

function sleep(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

function buttonState(loading) {
  if (loading) {
    // disable button
    $(button).prop("disabled", true);
    // add spinner to button
    $(button).html(
      `<span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
      &nbsp;Laden...`
    );
  } else {
    // disable button
    $(button).prop("disabled", false);
    // add spinner to button
    $(button).html(`Anfrage senden`);
  }
}

$(form).submit(function (e) {
  buttonState((loading = true));
  // preventing from page reload and default actions
  e.preventDefault();
  // serialize the data for sending the form data.
  var serializedData = $(this).serialize();
  // make POST ajax call
  $.ajax({
    type: "POST",
    url: postUrl,
    data: serializedData,
    success: async function (response) {
      $(".is-invalid").removeClass("is-invalid");
      await sleep(500);
      // on successfull creating object
      $(form).addClass("form-success");
      //$(form).trigger('reset');
    },
    error: async function (response) {
      await sleep(500);
      $(".is-invalid").removeClass("is-invalid");
      buttonState();
      // console.log(xhr.status);
      console.log(response);
      if (response.status == 404 || response.status == 0) {
        $(".non-field-errors")
          .children("p")
          .html("Es ist ein Fehler aufgetreten. Bitte versuchen Sie es später erneut.");
      } else if (response.status == 400) {
        // alert the error if any error occured
        for (const error in response["responseJSON"]["error"]) {
          // console.log(error);
          // console.log(response["responseJSON"]["error"][error][0]);
          if (error !== "start_date") {
            $("#id_" + error + "_feedback").html(response["responseJSON"]["error"][error][0]);
            $("#id_" + error).addClass("is-invalid");
          }
        }
      }
    }
  });
});
